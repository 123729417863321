.bdy{
    margin: 0;
    padding: 0;
    font-family: montserrat;
    background: linear-gradient( to left,
        orange,pink,purple);
   /*background: linear-gradient(to left,
    red,yellowgreen,pink); */
    height: 100vh;
    overflow: hidden;

}
.center{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 400px;
    background:rgba(238, 216, 172, 0.808);
    border-radius: 15px;
    height: 60%;
    margin-top: 60px;

}
.center h1{
    text-align: center;
    padding: 0 0 20px 0;
    border-bottom: 1px solid rgb(204, 19, 241);
    color: white;
}
.center form{
    padding: 0 40px;
    box-sizing: border-box;
}
form .txt_field{
    position: relative;
    border-bottom: 2px solid #000000;
    margin: 30px 0;
}
.txt_field input{
    width: 100%;
    padding: 0 5px;
    height: 40px;
    font-size: 16px;
    border: none;
    background: none;
    outline: none;

}
.txt_field label{
    position: absolute;
    top: 50%;
    left: 5px;
    color:#000000 ;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
    transition: .5s;
}
.txt_field span::before{
    content: '';
    position: absolute;
    top: 40px;
    left: 0;
    width: 0%;
    height: 1px;
    background: orchid;
    transition: .5s;
}
.txt_field input:focus ~ label,
.txt_field input:valid ~ label{
top: -5px;
color: orchid;
}
.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before{
width: 100%;
}
.pass{
    margin: -5px 0 20px 5px;
    color: #000000 ;
    cursor: pointer;
    text-align: center;

}
.pass :hover{
    text-decoration: underline;

}
input[type="submit"]{
    width: 100%;
    height: 35px;
    background:linear-gradient(to left,
    rgb(147, 23, 248),rgb(216, 10, 147), rgb(147, 23, 248));
    border-radius: 10px;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    margin: 0px;
  
}
input[type="submit"]:hover{
    transition: .5s;
    color:rgb(3, 3, 3);
    background:linear-gradient(to left,
    red,yellow,pink
  );
     
}