.all-content{
    background-color: white;
    text-align: center;
    border-radius: 50px;
    margin: 10px 15px;
    padding: 10px;
    padding-top: 5%;
}
.all-content  p {
    font-size: 35px;
    color: grey;
}
.all-content h3 {
    font-size: 70px;
}
.all-content >div {
    -webkit-box-shadow: 2px 15px 20px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 2px 15px 20px 10px rgba(0, 0, 0, 0.15);
}
.heading1{background-color:black;
    color:bisque;
    border-radius: 40px;
    

}
.heading1 h2{
    font-size: 60px;
}
.heading2{
    margin-top: 5%;
    background-color: white;
    border-radius: 50px;
    text-shadow: blue;
}