
.body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    
  }
  @media screen and (min-width:1200px){
  
    .h3{
      font-size: x-large;
    }
 
  }
    
  

    
  .part6 {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 100px;
  }
  .part6 .part6_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: flex-start;
    margin-top: 40px;
    font-size: 20px;
  }
  @media(max-width:840px){
    .part6 .part6_container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: flex-start;
      margin-top: 40px;
      font-size: 30px;
    }

  }
  .part6 .part6_container > div {
    width: 100%;
    margin: 10px;
    /* display: -webkit-box;
    display: -ms-flexbox; */
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    
    border-radius: 10px;
    -webkit-box-shadow: 2px 15px 20px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 2px 15px 20px 10px rgba(0, 0, 0, 0.15);
  } 
  .part6 .part6_container > div h2 {
    font-size: 30px;
  
  
  }
  
  .part6 .part6_container > div p {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
            width: 100%;
   
  }
  
  
  
  .part6 .part6_container > div button:hover {
    background: #4c35a6;
  }
  .h1A {
    color: red;
    border-left: 15px solid red;
    border-right: 15px solid red;
  }
  .h2A {
    color: white;
    background-color: red;
    border-radius: 50px;
    width:100%;
    
    
  }
  .bt1{
    float: right;
    margin-right: 0%;
    margin-top: 0%;
    color: white;
    background-color: black;
    border-radius: 30px;
    display: block;
    padding: 10px;
    

  }
  .link{
    text-decoration:none;
    color:white;
  }
  .h1-body{
    background-color: rgba(199, 200, 196, 0.395);
    border-radius: 10px;
  }
  .pp1{
    color: white;
    background-color: white;

  }
  .home-li a:hover {
    background-color: orchid;
  }
  .home-li a:visited {
    background-color: greenyellow;
  }
  .home-li{
    
    background-color:#fff;
  padding: 10px;
    border-radius: 50px;
    border-color:violet;
   
  }
  @media screen and (max-width:600px) {
    .part6 .part6_container > div {
      width:100%;
      
      padding: 8px 30px;

    }

    
  }
  .p-date{
    color: red;
    
  }
  .home-link-button{
    background: linear-gradient(135deg, #71b7e6);
    color: white;
    border-radius: 40px;
    align-items: right;
    width: 60%;
    border-left: pink;
   
  }
  
  .home-li {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-box;
    -webkit-box-shadow: 2px 15px 20px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 2px 15px 20px 10px rgba(0, 0, 0, 0.15);
            


  }
  
  
  
