
.main{
    padding: 30px 20px;
  }
  
  .cards{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-gap: 20px;
  
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10%;
    
  }
  
  .single-card{
    padding: 20px 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .single-card:nth-child(1){
    border-left: 15px solid royalblue;
    background-color:linear-gradient(90deg,#6616d0,#ac34e7);
  }
  .single-card:nth-child(2){
    border-left: 15px solid limegreen;
    background-color:yellow;
  }
  .single-card:nth-child(3){
    border-left: 15px solid teal;
    background-color: blueviolet
  }
  .single-card:nth-child(4){
    border-left: 15px solid orange;
    background-color: aquamarine
  }
  .single-card:nth-child(5){
    border-left: 15px solid violet;
    background-color: rgb(161, 255, 127);
  }
  
  
  .single-card:nth-child(1) span{
    color: royalblue;
  }
  
  .single-card:nth-child(2) span{
    color: limegreen;
  }
  .single-card:nth-child(3) span{
    color: red;
  }
  .single-card:nth-child(4) span{
    color: orange;
  }
  .single-card:nth-child(5) span{
    color:violet;
  }
  
  
  .single-card:hover{
    background-color: var(--main-color);
    color: var(--text-white);
  }
  
  .single-card:hover :is(span,i,h2){
    color: var(--text-white);
    opacity: 1;
  }
  
  .single-card span{
    font-size: 25px;
    font-weight: 700;
  }
  
  .single-card h2{
    font-size: 24px;
    font-weight: 500;
    color: #555;
  }
  
  .single-card i{
    font-size: 35px;
    margin-right: 15px;
    color: var(--text-grey);
    opacity: .5;
  }
  