.job-card-head{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 10px;
}
.job-card-head>div{
    width: 100%;
    margin: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: white;
   
    padding: 20px 30px;
    -webkit-box-shadow: 2px 15px 20px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 2px 15px 20px 10px rgba(0, 0, 0, 0.15);
    
    

}
.job-card1{
    background-color: #cc33ff;
}
.job-card2{
    background-color: #000066;
}
.job-card3{
    background-color:#660066;
}
.job-card4{
    background-color: #660033;
}
.job-card6{
    background-color: #660033;
}
.job-card7{
    background-color: #ee0b7d;
}
.job-card8{
    background-color: #662b00;
}
.job-card5{
    width: auto;
    margin: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: white;
    background-color: #000066;
   
    padding: 20px 30px;
    -webkit-box-shadow: 2px 15px 20px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 2px 15px 20px 10px rgba(0, 0, 0, 0.15);
            -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    

}
@media(max-width:840px){
    .job-card1{
        padding: 5px 10px;
    }
    .job-card1 h2 ,.job-card2 h2,.job-card3 h2,.job-card4 h2,.job-card5 h2,.job-card6 h2,.job-card7 h2,.job-card8 h2{
        font-size: 15px;

    }

    .job-card-head>div{
        padding: 5px 20px;
        margin: 2px;
    }

}