.main{
    
    max-width: 900px; 
    margin: 0 auto; 
    border: 1px solid #333;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}


.Header {
    background-color: #6f42c1; 
border: 2px solid #ccc; 
margin-top: 50px;
text-align: center;
font-size: 20px;
color: wheat;
}
 
  p {
    margin: 20px; 
    padding: 2px;

  } 
/* style1.css

/* .main {
max-width: 900px;
margin: 0 auto;
color: #6f42c1;
padding: 20px; 
}

.Header1 {
background-color: #6f42c1;
color: white;
padding: 10px;
margin-bottom: 20px; 
}

h3 {
margin-bottom: 10px; 
}

p {
margin-bottom: 15px; 
}
*/