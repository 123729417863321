nav{
    width: 100%;
    background:snow;
    padding: 4px 0;
    position: fixed;
    top: 0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index:10;
}
.logo{
    width: 180px;
}
nav ul li{
    display: inline-block;
    list-style: none;
    margin: 5px 20px;
    font-size: 16px;
}
.menu{
    display: none;
}
.container1 h2{
    color: RED;
}
.container1 h2 span{
    color: black;
    font-size: 10px;

}

.main-image{
  width: 100%;
  margin: 5px 20px;
}
.btn-btn{
  background-color: black;
  color: white;
  padding: 14px 25px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  border: o;
  outline: 0;
}
a{
  text-decoration: none;
  color:blue;
  line-height: 1;
  cursor: pointer;
}
/*       media -----------------query       */
@media (max-width:1200px){
    .container1{
      padding-left :5%;
      padding-right:5%
    }
    .btn-btn{
      padding: 14px 22px;
    }
  }
  @media (max-width:1000px) {
    .logo{
      width: 120px;
      
    }
    nav ul li{
      margin:0px 8px;
    }
  
    
  }
  @media (max-width:840px){
    nav{
        padding: 15px 0;;
    }
    nav ul{
      position: fixed;
      top:0;
      right:0;
      bottom: 0;
      background: aqua;
      z-index:-1;
      width: 200px;
      padding-top:70px;
      transition:0.5s
    }
    nav ul li{
        display:block;
        margin:25px 40px;
    }
    .btn-btn{
    padding: 5px 40px;

    }
    .menu{
        display: block;
        width:30px;
        cursor: pointer;
    }
    .hide-menu{
        right: -200px;
    }
    .main-image{
      width: 100%;
      margin: 10px 30px;
    }
  
  }
  @media (min-width:740px){
    nav{
        padding: 15px 20px;;
    }
    nav ul{
      position: fixed;
      top:0;
      right:0;
      bottom: 0;
      background: aqua;
      z-index:-1;
      width: 200px;
      padding-top:70px;
      transition:0.5s
    }
    nav ul li{
        display:block;
        margin:25px 40px;
    }
    .btn-btn{
    padding: 5px 40px;

    }
    .menu{
        display: block;
        width:30px;
        cursor: pointer;
    }
    .hide-menu{
        right: -200px;
    }
    .main-image{
      width: 100%;
      margin: 10px 30px;
    }
  
  }

  