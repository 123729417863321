*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'poppins',sans-serif;
    }
    .bdy{
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
      
        background: linear-gradient( to left,
        orange,pink,purple);
    }
    .container{
        max-width: 700px;
        width: 100%;
        background: #fff;
    padding: 15px 30px;
    border-radius: 10px;
    }
    .container .title{
        font-size: 35px;
        font-weight: 500;
        position: relative;
    }
    .container .title::before{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        width: 190px;
        background: linear-gradient( to left,
        orange,pink,purple);
        margin: -5px;
       
    }
    .container form .user-details{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 20px 0 12px 0;
    }
    form .user-details .input-box
    {
    margin-bottom:  15px;
        width: calc(100% / 2 - 20px);
    }
    
    .user-details .input-box.details{
        display: block;
        font-weight: 500;
        margin-bottom: 5px;
    
    }
    .user-details .input-box input{
        height: 45px;
        width: 100%;
        outline: none;
        border-radius: 5px;
        border: 1px solid#ccc;
        padding: 15px;
        font-size: 16px;
        border-bottom-width: 2px;
        transition: all 0.5s ease;
    }
    .user-details .input-box input:focus,
    .user-details .input-box input:valid{
    border-color:#9b59b6;
    }
    form .gender-details .gender-title{
        font-size: 20px;
        font-weight: 500;
    
    }
    form .gender-details .category{
        display: flex;
      width: 180%;
    
      margin: 14px 0;
      justify-content: space-between;
    
    }
    .gender-details .category label{
    display: flex;
    align-items: center;
    }
    .gender-details .category .dot {
        height: 18px;
        width: 18px;
        background: #d9d9d9;
        border-radius: 50%;
        margin-right: 10px;
        border: 5px solid transparent;
        transition: all 0.5s ease;
        
    } 
    #dot-1:checked ~ .category label .one,
    #dot-2:checked ~ .category label .two{
        border-color:#d9d9d9;
        background:#9b59b6;
    }
        form input[type="radio"]{
            display: none;
        }
        form .button{
            height: 35px;
            margin-right: 100% ;
        }
    
     form .button input{
            height: 100%;
            width: 642px;
             outline: none;
             color: #fff;
             border: none;
             font-size: 23px;
             font-weight: 500;
             border-radius: 8px;
             letter-spacing: 1px;
             background: linear-gradient( to left,
             orange,pink,purple)
        }
        form .button input:hover{
            color: black;
            background: linear-gradient(to left, 
            purple,pink ,orange)
        
        
        }