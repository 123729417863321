.lat-div-head{
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 2%;
}
.lat-div{
    width: 28%;
    margin: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 5px 20px;
    border-radius: 10px;
    -webkit-box-shadow: 2px 15px 20px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 2px 15px 20px 10px rgba(0, 0, 0, 0.15);
            background-color:aqua;
}
.job-d{
    background-color: darkgray;
    text-align: center;
    border-radius: 50px;
}
.div1{
    color: black;
    background-color: white;
    border-radius: 50px;
    display: block;
    padding: 20px 60px;
    margin: 10px;
    text-align: justify;
    text-align: center;


}   
.job-body{
    background-color: rgb(255, 204, 255);
}
.job-h2{
    color:  white;
    background: linear-gradient(135deg, #71b7e6, #9b59b6);
    display: block;
    border-radius: 50px;
margin-left: 20% ;
margin-right: 20%;

    
}
