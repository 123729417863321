.nav-content{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
    
  }
  .nave-item{
    font-size: 80px;
  }
  
  .nav-li {
    float: left;
  }
  
  .a-link {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 70px;
    text-decoration: none;
  }
  
  li a:hover {
    background-color: red;
  }
  .nav-body{
    background-color: black;
    position: fixed;
    width: 100%;
    height: auto;
    margin-top: 4.5%;
  }
  
.linklink{
  text-transform: none;
  text-decoration: none;
  color:white;
}
@media only screen and (max-width: 1200px) {
  .nav-body {
    margin-top: 7.0%;
    
  }
}
@media (max-width:840px){
  .nave-item{
    font-size: 20px;
  }
  .nav-body{
   font-size: 10px;
  }

}
 