.sp1{color:aliceblue;background-color: red;font-size:10px;}
marquee{width:100%;font-size: 15px;}
.mar-body{
    width:100%;
background-color:blanchedalmond;
border-radius: 10px;
padding:60px 10px;
margin: 35px 10px;
}
@media(max-width:840px){
    marquee{
    width:100%;
    font-size: 10px;
    display: block;

    }
    .mar-body{
        width:100%;
        padding:50px 5px;
        margin: 35px 5px;
}
}
