.lat-page-body{
    padding-top: 9%;

}
.lat-button{
    background-color: purple;
    color: white;
    border-radius: 50px;
    width: 15%;
    
}