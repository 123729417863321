.form-data{
    text-align: center;
    padding-top: 10%;
    background-color: aqua;
}
.input{
    padding-left: 10%;
     background-color: antiquewhite;
    border-radius: 50px;
    justify-content: center;
}
.input1{
    width: 60%;
    
}
label{
    padding: 20px;
    display: block;
}
.add-button{
    background-color: #04AA6D; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.add-button1{
    background-color:red; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
::placeholder{
    color:darkgrey;
    text-align: center;
    
}