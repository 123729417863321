.footer {
    width: 100%;
  }
  
  .footer .footer_upper {
    padding: 20px 10%;
    background-color: #26262e;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    color: white;
  }
  
  .footer .footer_upper .footer_list {
    margin: 10px;
    width: 140px;
    line-height: 30px;
    text-align: left;
  }
  
  .footer .footer_upper .footer_list h3 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .footer .footer_upper .footer_list li {
    list-style: none;
    font-size: 14px;
  }
  
  .footer .footer_upper .footer_list a {
    text-decoration: none;
    color: white;
  }
  
  .footer .footer_lower {
    min-height: 80px;
    padding: 0 10%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    background-color: #000;
    color: white;
  }
  
  .footer .footer_lower .footer_lower_left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 14px;
  }
  
  .footer .footer_lower .footer_lower_left .logo {
    overflow: hidden;
    -o-object-fit: contain;
       object-fit: contain;
    max-height: 50px;
    height: 50px;
    width: 70px;
    margin: 10px;
    background-color: white;
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 30px;
  }
  
  .footer .footer_lower .footer_lower_left .logo img {
    width: 100%;
    height: 100%;
  }
  
  .footer .footer_lower .footer_lower_right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .footer .footer_lower .footer_lower_right .logos {
    margin: 10px;
  }