table,th{border: 2px solid black;width:auto;height: auto;}
.table1,th{ border:2px solid black;width:auto ;height: auto;}
.table2,th{border:2px solid black;width: auto;height: auto;}
.table3,th{border:2px solid black;width: auto;height: auto;}
.tables,th{border:2px solid black;width: auto;height: auto;}
.tables1,th{border:2px solid black;width: 10%;;height: auto;}
.exam-div  > p{
    padding: 20px;
}


