.background{
    background-color: rgb(19, 0, 128);
    
  position: fixed;
    width: 100%;
    margin-bottom: 120px;
    
   
 
  
}
   

.nav-head h1{
  text-align: center;
  color: white;
  font-size: 35px;
}
.head-span h1{
  color: orange;
  font-size: 13px;
}
.head-span{
 text-align: center;
 margin-bottom: 40px;

}

.nav-head img {
  width:40px;
  margin-right: 60px;

}
