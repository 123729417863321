
.con-div {
    max-width: 80%; 
    margin: 0 auto; 
    border: 1px solid #333;
    font-size: 17px;
    font-display: 5 px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    
  }
  
  
.con-h2{  
    background-color: #6f42c1; 
    border: 2px solid #ccc; 
    margin-top: 50px; 
    padding: 10px; 
    text-align: center;
    font-size: 30px;
    color: wheat;
}


  
  
  .Heading-child {
    color: wheat;
  }
  
  
  p {
    margin: 20px; 
    padding: 5px;

  }
  
  
  ul {
    list-style-type: disc; 
    margin-bottom: 13px; 
  }
  
 
  li {
    margin-bottom: 5px; 
  }
  
 
  
  
  .con-h3 {
    background-color: #6f42c1; 
    border: 2px solid #ccc; 
    margin-top: 50px; 
    padding: 10px; 
    text-align: center;
    font-size: 30px;
  }
  
 
  