* {
    font-family: 'Times New Roman', Times, serif;
    
}
.header{
    padding-top: 10%;
}

.header-child {
    margin-bottom: 50px;
    background-color:purple;
    color: white;
    height: 50px; 
    text-align: center;
    border-radius: 20px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    background-position: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    align-items: center;
}

.header-child-1 {
    padding: 5px;
    border: 1px solid #ddd; 
    border-radius: 10px; 
    background-color: #f5f5f5; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    text-align: center;
}

.service-heading1 {
    text-align: center;
    font-size: 40px;
}

.service-content1 {
    margin: 30px;
    padding: 20px;
    font-size: 15px;
    font-style: italic;
    border: 1px solid #ddd; 
    border-radius: 10px; 
    background-color: #f5f5f5; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    text-align: center;
}

.firstpic1 {
    padding: 20px;
    border: 1px solid #ddd; 
    border-radius: 10px; 
    background-color: #f5f5f5; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    text-align: center;
}

/* Responsive Styles */

@media only screen and (max-width: 600px) {
    .service-content1 {
        padding: 10px;
        margin: 15px;
    }

    .firstpic1 {
        padding: 10px;
    }
}
